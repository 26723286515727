import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { randomInt } from 'client/utils/seed-randomizers';
import { isEmpty, noop } from 'lodash';

// Models
import { bindToPath, connectToModel } from 'client/data/luckdragon/redux/react-binding';
import {
  VehicleVinModel,
  buildStylesBasicPathFromSquishVin,
  buildStyleBasicPathFromVinDecoder,
} from 'client/data/models/vehicle-vin';

// Utils
import { getSquishVIN } from 'site-modules/shared/utils/vin-utils';
import { EventToolbox } from 'client/utils/event-toolbox';
import { delayHeavyTask } from 'client/site-modules/shared/utils/inp-utils';

// Constants
import { TrackingConstant } from 'client/tracking/constant';
import { APPRAISAL_VEHICLE_ENTRY_CREATIVE_ID } from 'client/site-modules/shared/constants/appraisal/appraisal';
import { APPRAISE_BY_VIN_TAB_EVENT_DATA } from 'site-modules/shared/components/appraisal/appraisal-tabs/constants';

// Context
import { useAppraisalTabsContext } from 'site-modules/shared/components/appraisal/appraisal-tabs/appraisal-tabs-context';

// Components
import { AppraiseTabContentFormWrapper } from 'site-modules/shared/components/appraisal/appraisal-tabs/appraise-tab-content-form-wrapper';
import { FindVinModal } from 'site-modules/shared/components/find-vin-modal/find-vin-modal';

export function VinTabContentUI({
  inputSlot,
  submitButtonSlot,
  isVinValid,
  wasFormSubmitted,
  squishStyles,
  vinStyles,
  isLookUpStyleView,
  isFullWidth,
  profileOfferVin,
  hasAddressToVinDrawer,
  isBackgroundStyleTabEnabled,
  addressToVinDrawer,
  resetIsSubmitting,
  fireVinErrorTracking,
  creativeId,
  isSubmitting,
  isSquishVinInvalid,
  isEval4351Chal,
  onFindVinModalOpen,
  onFindVinModalClose,
  isEmo2507Chal,
}) {
  const {
    mmy,
    vin,
    setMmy: setContextMmy,
    setSquishStyles: setContextSquishStyles,
    setVinStyles: setContextVinStyles,
    setIsReadyForRedirect,
    isProjectEveStyle,
    emo2507Recipe,
  } = useAppraisalTabsContext();
  const uniqInputId = useRef('');

  useEffect(() => {
    uniqInputId.current = randomInt();
  }, []);

  const firstSquishStyleId = squishStyles ? squishStyles[0]?.id ?? null : undefined;
  let firstVinStyleId;

  if (!isEmpty(vinStyles)) {
    firstVinStyleId = vinStyles?.styles?.[0]?.id ?? null;
  }

  useEffect(() => {
    setContextSquishStyles(squishStyles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstSquishStyleId]); // Use the first ID in the hook dependencies to prevent extra calls

  useEffect(() => {
    setContextVinStyles(vinStyles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstVinStyleId]); // Use the first ID in the hook dependencies to prevent extra calls

  useEffect(() => {
    if (isSquishVinInvalid && isSubmitting) {
      resetIsSubmitting();
    }
  }, [isSquishVinInvalid, isSubmitting, resetIsSubmitting]);

  const isReadyForUpdate =
    (isEmo2507Chal ? !!vinStyles : !!squishStyles) && wasFormSubmitted && isVinValid && isSubmitting;
  const hasSquishStyles = !!squishStyles && squishStyles.length > 0;

  useEffect(() => {
    if (!isReadyForUpdate) {
      return;
    }

    if (!hasSquishStyles) {
      fireVinErrorTracking(TrackingConstant.SUBACTION_SQUISHVIN_DECODE, TrackingConstant.MMY_NOT_FOUND);
      return;
    }

    EventToolbox.fireTrackAction({
      event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
      event_data: {
        ...APPRAISE_BY_VIN_TAB_EVENT_DATA,
        creative_id: creativeId,
        action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
        subaction_name: TrackingConstant.SUBACTION_SQUISHVIN_DECODE,
        value: squishStyles.length > 1 ? TrackingConstant.MMY_MANY_FOUND : TrackingConstant.MMY_ONE_FOUND,
      },
    });

    if (!isEmo2507Chal) {
      const { makeNiceId, modelNiceId, year } = squishStyles[0];
      setContextMmy({ make: makeNiceId, model: modelNiceId, year });
    }

    if (isEmo2507Chal) {
      const { matchType } = vinStyles;
      const stylesLengthValue = vinStyles?.styles?.length;
      let matchTypeValue = TrackingConstant.SQUISHVIN;
      let exteriorColorValue = null;
      let interiorColorValue = null;

      // Having matchType means VIN data
      if (matchType) {
        matchTypeValue = matchType.toLowerCase();
        exteriorColorValue = vinStyles?.exteriorColor?.oemName;
        interiorColorValue = vinStyles?.interiorColor?.oemName;
      }
      const { makeSlug, modelSlug, year } = vinStyles?.modelYear;
      setContextMmy({ make: makeSlug, model: modelSlug, year });

      EventToolbox.fireTrackAction({
        event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
        event_data: {
          ...APPRAISE_BY_VIN_TAB_EVENT_DATA,
          creative_id: creativeId,
          action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
          subaction_name: TrackingConstant.SUBACTION_VIN_DECODE_STYLES,
          value: stylesLengthValue,
        },
      });
      EventToolbox.fireTrackAction({
        event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
        event_data: {
          ...APPRAISE_BY_VIN_TAB_EVENT_DATA,
          creative_id: creativeId,
          action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
          subaction_name: TrackingConstant.SUBACTION_VIN_DECODE_MATCH_TYPE,
          value: matchTypeValue,
        },
      });
      if (emo2507Recipe === 'chal2') {
        EventToolbox.fireTrackAction({
          event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
          event_data: {
            ...APPRAISE_BY_VIN_TAB_EVENT_DATA,
            creative_id: creativeId,
            action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
            subaction_name: TrackingConstant.SUBACTION_VIN_DECODE_EXTERIOR_COLOR,
            value: exteriorColorValue ?? null,
          },
        });
        EventToolbox.fireTrackAction({
          event_type: TrackingConstant.EVENT_TYPE_ACTION_PROGRESS,
          event_data: {
            ...APPRAISE_BY_VIN_TAB_EVENT_DATA,
            creative_id: creativeId,
            action_category: TrackingConstant.SYSTEM_ACTION_CATEGORY,
            subaction_name: TrackingConstant.SUBACTION_VIN_DECODE_INTERIOR_COLOR,
            value: interiorColorValue ?? null,
          },
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReadyForUpdate]);

  // Make sure all data is set and initiate the redirect
  useEffect(() => {
    if (isReadyForUpdate && !isEmpty(mmy) && !!vin) {
      delayHeavyTask(() => {
        setIsReadyForRedirect(true);
      });
    }
  }, [isReadyForUpdate, mmy, setIsReadyForRedirect, vin]);

  const isFindVinModalDisplayed = !profileOfferVin && !isLookUpStyleView;

  const btnColLgSize = isBackgroundStyleTabEnabled || isProjectEveStyle ? 4 : 12;
  const vinInputColLgSize = isBackgroundStyleTabEnabled || isProjectEveStyle ? 6 : 12;

  return (
    <Row className="appraise-by-vin-tab">
      <Col xs={12} lg={vinInputColLgSize}>
        <AppraiseTabContentFormWrapper isFullWidth={isFullWidth}>
          <Row>
            <Col xs={12}>
              {inputSlot}
              {isEval4351Chal && addressToVinDrawer}
            </Col>
          </Row>
        </AppraiseTabContentFormWrapper>
      </Col>
      <Col xs={12} lg={btnColLgSize}>
        {isFindVinModalDisplayed && !hasAddressToVinDrawer && !isBackgroundStyleTabEnabled && !isProjectEveStyle && (
          <FindVinModal
            className="mb-1 p-0 border-0 text-primary-darker text-transform-none size-16 find-vin-modal-open-btn"
            creativeId={APPRAISAL_VEHICLE_ENTRY_CREATIVE_ID}
            onOpen={onFindVinModalOpen}
            onClose={onFindVinModalClose}
          />
        )}
        {submitButtonSlot}
      </Col>
    </Row>
  );
}

VinTabContentUI.propTypes = {
  inputSlot: PropTypes.node,
  submitButtonSlot: PropTypes.node,
  isVinValid: PropTypes.bool,
  wasFormSubmitted: PropTypes.bool,
  squishStyles: PropTypes.arrayOf(PropTypes.shape({})),
  vinStyles: PropTypes.shape({}),
  isLookUpStyleView: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  profileOfferVin: PropTypes.string,
  hasAddressToVinDrawer: PropTypes.bool,
  isBackgroundStyleTabEnabled: PropTypes.bool,
  addressToVinDrawer: PropTypes.node,
  resetIsSubmitting: PropTypes.func.isRequired,
  fireVinErrorTracking: PropTypes.func.isRequired,
  creativeId: PropTypes.string,
  isSubmitting: PropTypes.bool,
  isSquishVinInvalid: PropTypes.bool,
  isEval4351Chal: PropTypes.bool,
  onFindVinModalOpen: PropTypes.func,
  onFindVinModalClose: PropTypes.func,
  isEmo2507Chal: PropTypes.bool,
};

VinTabContentUI.defaultProps = {
  inputSlot: null,
  submitButtonSlot: null,
  isVinValid: false,
  wasFormSubmitted: false,
  squishStyles: null,
  vinStyles: null,
  isLookUpStyleView: false,
  isFullWidth: false,
  profileOfferVin: '',
  hasAddressToVinDrawer: false,
  isBackgroundStyleTabEnabled: false,
  addressToVinDrawer: null,
  creativeId: APPRAISAL_VEHICLE_ENTRY_CREATIVE_ID,
  isSubmitting: false,
  isSquishVinInvalid: false,
  isEval4351Chal: false,
  onFindVinModalOpen: noop,
  onFindVinModalClose: noop,
  isEmo2507Chal: false,
};

export const stateToPropsConfig = {
  squishStyles: bindToPath(
    ({ vin }) => buildStylesBasicPathFromSquishVin({ squishVin: getSquishVIN(vin) }),
    VehicleVinModel
  ),
  vinStyles: bindToPath(
    ({ vin, isEmo2507Chal }) => (isEmo2507Chal ? buildStyleBasicPathFromVinDecoder({ vin }) : ''),
    VehicleVinModel
  ),
};

export const VinTabContent = connectToModel(VinTabContentUI, stateToPropsConfig);
